import React, { useState, useContext, useEffect, useRef } from 'react'
import { Consumer, Context } from '../../context'
import { Redirect } from 'react-router'

import StepWizard from 'react-step-wizard'
import Step from '../Step'
import { useHistory } from 'react-router-dom'

import BuildSearch from '../Step/BuildSearch'
import SelectFileType from '../Step/SelectFileType'
import ConfigureFile from '../Step/ConfigureFile'
import Schedule from '../Step/Schedule'

import ProgressManager from '../ProgressManager'
import { doSave, getFullCurrentStepData, statusIdMapping } from '../Step/helpers/buildSearchHelper'
import { LoadingChart } from 'talentneuron---ui-components-library'
import NameDuplicationModal from '../Step/NameDuplicationModal'

import FeedsApi from '../../httpService/feeds'

function Wizard () {
  let history = useHistory()
  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    // if it is direct navigation then redirect to home page of DF
    if (history.action === 'POP') {
      history.push('/')
    }
  }, [])

  let dataFeedStepsState = [
    {
      name: 'Build search',
      stepComplete: false,
      SFTPStepComplete: true,
      clickInactiveSaveContinue: 0,
      hashKey: 'build-search',
      component: BuildSearch,
      state: null,
      order: 1,
      showValidationErrorHandler: () => {
        showBuildSearchValidationErrMsg()
      }
    },
    {
      name: 'Select file type',
      stepComplete: false,
      SFTPStepComplete: true,
      clickInactiveSaveContinue: 0,
      hashKey: 'select-file-type',
      component: SelectFileType,
      state: { fieldsList: [] },
      order: 2,
      showValidationErrorHandler: () => {}
    },
    {
      name: 'Configure file',
      stepComplete: false,
      SFTPStepComplete: true,
      clickInactiveSaveContinue: 0,
      hashKey: 'configure-file',
      component: ConfigureFile,
      state: null,
      order: 3,
      showValidationErrorHandler: () => {}
    },
    {
      name: 'Schedule',
      stepComplete: false,
      SFTPStepComplete: true,
      clickInactiveSaveContinue: 0,
      hashKey: 'schedule',
      component: Schedule,
      state: null,
      order: 4,
      showValidationErrorHandler: () => {}
    }
  ]

  const globalState = useContext(Context)
  const currentFeedData = globalState.isEditMode ? globalState.currentFeedData : []
  const [steps, setSteps] = useState(dataFeedStepsState)
  let [loading, setLoading] = useState(false)
  let [duplicatedName, setDuplicatedName] = useState(null)
  const stepRef = useRef()
  const [fullData, setFullData] = useState({
    geminiUserId: globalState.userdetails.userid,
    statusId: statusIdMapping.inComplete,
    isdeleted: false,
    ...currentFeedData,
    clientName: globalState.userdetails.clientname
  }) // TODO remove the id after testing

  let [enableCheck, setEnableCheck] = useState(false)
  let [savingInProgress, setSavingInProgress] = useState(false)
  let [completeDataFeedClicked, setCompleteDataFeedClicked] = useState(false) // State for "Complete Data feed" button
  const showBuildSearchValidationErrMsg = () => {
    if (!steps[0].StepComplete) {
      if (steps[0].state.searchName.length < 1) {
        globalState.setBuildValidationErrorMsg('Please provide a valid search name')
      } else {
        globalState.setBuildValidationErrorMsg('Please select atleast one filter to continue')
      }
    }
  }
  const [redirect, setRedirect] = useState(false)
  useEffect(() => {
    if (completeDataFeedClicked) {
      (async () => {
        const result = await saveDataFeed(4)
        if (result) {
          setRedirect(true)
        }
      })()
    }
  }, [completeDataFeedClicked])

  const saveDataFeed = async (currentStep, isDuplicate = false, name = '') => {
    setLoading(true)
    setEnableCheck(true)
    setSavingInProgress(true)
    const totalSteps = 4
    let stateToSave = fullData
    let continueAfterSave = false
    let statusId = 2
    setDuplicatedName(null)
    if (isDuplicate) {
      const dfId = globalState.dataFeedList?.filter(feed => feed.name === name)[0].id
      stateToSave.id = dfId
      stateToSave.name = 'test texas'
    }
    let isCurrentStepCompleted = false
    steps.map((step, id) => {
      if (currentStep === step.order) {
        isCurrentStepCompleted = step.stepComplete
        const data = getFullCurrentStepData(stateToSave, step)
        stateToSave = { ...stateToSave, ...data }
      }
    })
    const savedData = await doSave(stateToSave, currentStep, isCurrentStepCompleted, totalSteps, globalState?.userdetails.dfpermissions.isSFTPConfigured, completeDataFeedClicked) // Passing completeDataFeedClicked here
    if (savedData.data) {
      setLoading(false)
      setEnableCheck(false)
      const id = savedData.data.id || savedData.data[0].id
      setFullData({ id, ...stateToSave })
      const currentKey = dataFeedStepsState.filter(el => el.order === currentStep)[0].hashKey

      globalState.dispatch({ type: 'set_feed_data', payload: { id, currentStep: currentKey, ...globalState.currentFeedData, ...stateToSave } })
      continueAfterSave = true
    } else {
      if (savedData.errorData) {
        const message = savedData.errorData.message
        if (message.split('=')[0].indexOf('name, gemini_user_id') >= 0 && message.split('=')[1].indexOf('already exists') >= 0) {
          const namePart = message.split('=')[1].split(',')[0]
          const name = namePart.substring(1, namePart.length)
          setDuplicatedName(name)
        }
      }
      setLoading(false)
    }
    setCompleteDataFeedClicked(false)
    setSavingInProgress(false)
    return continueAfterSave
  }
  const closeDuplicatedNameModal = () => {
    setDuplicatedName(null)
    setLoading(false)
  }

  const onReplaceClick = async (name) => {
    await saveDataFeed(1, true, name)
    stepRef.current.goNext()
  }

  let setStepComplete = (isCompleted, hashKey, stepState, isSFTPComplete) => {
    setSteps(prev => {
      return prev.map(elt => {
        if (elt.hashKey === hashKey) {
          elt.stepComplete = !!isCompleted
          elt.state = stepState

          if (elt.state?.deliverWay == 'SFTPS') {
            elt.SFTPStepComplete = !!isSFTPComplete
          }
        }
        return elt
      })
    })
  }

  let onClickInactiveSaveContinue = (hashKey) => {
    setSteps(prev => {
      return prev.map(elt => {
        if (elt.hashKey === hashKey) {
          elt.clickInactiveSaveContinue++
        }
        return elt
      })
    })
  }
  console.log('completedatafeedclicked', completeDataFeedClicked)
  return (
    <Consumer>
      {value => {
        return (
          <React.Fragment>
             {redirect && <Redirect push to="/" />}
            <StepWizard className={`${loading ? 'dataFeedLoading' : ''}`} nav={<ProgressManager steps={steps} />} isHashEnabled={true} transitions={{ enterRight: null }}>
              {steps.map((step, id) => {
                var component = step.component
                let Elt = React.createElement(component, {
                  steps: steps,
                  setStepComplete,
                  hashKey: step.hashKey,
                  clickInactiveSaveContinue: step.clickInactiveSaveContinue,
                  key: id,
                  enableCheck: enableCheck,
                  completeDataFeedClicked,
                  setCompleteDataFeedClicked,
                  ...value
                })

                return (
                  <Step
                    ref={stepRef}
                    key={id}
                    hashKey={step.hashKey}
                    isStepComplete={step.stepComplete}
                    stepState={step.state}
                    showValidationErrorHandler={step.showValidationErrorHandler}
                    saveDataFeed={saveDataFeed}
                    isSFTPStepComplete={step?.state?.deliverWay === 'SFTPS' && step?.state?.folder.length && step.order === 4}
                    onClickInactiveSaveContinue={() => onClickInactiveSaveContinue(step.hashKey)}
                    onBack={() => setStepComplete(false, step.hashKey, dataFeedStepsState[id].state)}
                    enableCheck={enableCheck}
                    completeDataFeedClicked={completeDataFeedClicked} // Passing completeDataFeedClicked
                    setCompleteDataFeedClicked={setCompleteDataFeedClicked}
                  >
                    {Elt}
                    {loading && (
                      <div className={'loading-container'}>
                        <LoadingChart title='' subTitle='' />
                      </div>
                    )}
                    {duplicatedName && (
                      <NameDuplicationModal
                        handleShowModal={closeDuplicatedNameModal}
                        onReplaceClick={onReplaceClick}
                        name={duplicatedName}
                      />
                    )}
                  </Step>
                )
              })}
            </StepWizard>
          </React.Fragment>
        )
      }}
    </Consumer>
  )
}

export default Wizard
